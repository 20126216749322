import Router from 'next/router';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

export enum DialogType {
  NONE = 'NONE',
  IMPERSONATION = 'IMPERSONATION',
  KYB_KYC_PASS = 'KYB_KYC_PASS',
  REFUND_PAYMENT = 'REFUND_PAYMENT',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',
  SUBSCRIBER_LIST = 'SUBSCRIBER_LIST',
}

export type DialogState = {
  type: DialogType;
  pathname: string;
  search?: string;
  data?: Record<string, unknown> | null;
};

const initialState = {
  open: false,
  type: DialogType.NONE,
  details: {} as Record<string, unknown>,
  stack: [] as DialogState[],
  showCloseIcon: false,
  closeOnClickOutside: false,
  closeOnEscapeKeyDown: true,
  className: '',
  closeCallback: () => {},
};

export const dialogManager = proxy(initialState);

devtools(dialogManager, {
  name: 'Global Dialog',
  enabled: false,
});

export const openDialog = (
  type: DialogType,
  details?: Partial<Omit<typeof initialState, 'open' | 'stack'>>,
) => {
  Object.assign(dialogManager, initialState, {
    open: true,
    type,
    ...details,
  });
};

export const closeDialog = () => {
  Object.assign(dialogManager, initialState);
};

export const saveDialogState = (type: DialogType, data?: Record<string, unknown> | null) => {
  dialogManager.stack.push({
    type,
    pathname: window.location.pathname,
    search: new URLSearchParams(window.location.search).toString(),
    data,
  });
};

export const getDialogState = (type?: DialogType) => {
  const dialog = dialogManager.stack.pop();
  if (!dialog) return null;
  if (type && dialog.type !== type) return null;
  return dialog;
};

export const restoreDialog = () => {
  const previousDialog = dialogManager.stack.at(-1);
  if (!previousDialog) return;
  const { pathname, search } = previousDialog;
  Router.replace([pathname, search].join('?'), undefined, {
    shallow: true,
  });
};
